import { PRODUCTION, STAGING, QAS, DEV, LOCALHOST } from "./constants/env";
// Main App
export const DOMAIN_URL = "https://dev.sunhub.com/";
export const API_URL = "https://api.dev.sunhub.com";
export const BLOG_URL = "https://dev.sunhub.com/blog/";

// Admin Side
export const ADMIN_URL = "https://admin.dev.sunhub.com/";
// Trader Platform
export const BROKER_DOMAIN_URL = "https://broker-api.dev.sunhub.com/api";
export const BROKER_SOCKET_URL = "https://broker-api.dev.sunhub.com";

// S3 Images URL
export const S3_IMAGES_BASE_URL = "https://media.dev.sunhub.com";
export const IMAGES_BASE_URL = "https://media.dev.sunhub.com/";
export const IMAGES_BASE_URL1 = "https://media.dev.sunhub.com/";

// App Setting
export const VERSION = "/v1";
export const STRIPE_KEY =
	"pk_test_51HHt9oFAlmFL3wjZHhhgzSbY15AztM3M0VmT92JxxQ9aA6JBWqGOOBvp3jpA23Cn7Xo5N5sD6R1RSedPB0YBfjr1003STuBfCX";
export const RESELLER_IDs = [
	"m.ali@basecampdata.com",
	"sadia.shabbir@basecampdata.com",
	"maak@mailinator.com",
	"sunhub.reseller@mailinator.com",
	"ultrasolar@mailinator.com",
];
export const CALENDLY_MEETING_LINK = "https://calendly.com/solar-trader/30min";
export const CONSOLE_LOG = true;
export const FB_ID = 1349329476048852; //3258557697492983 Test app api
export const GOOGLE_CLIENT_ID = "72483669082-7t43svhgf5v4hsga9dqcce6ubcgvooqr.apps.googleusercontent.com";
export const SOCIAL_LOGIN_Authorized_DOMAIN = [PRODUCTION, STAGING, QAS, DEV, LOCALHOST];
